import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCircle } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

export default function NavbarButton(props){
    const [isNavbarOn, setIsNavbarOn] = useState(false)
    function toggleNavbar(){
        setIsNavbarOn(prevNav => !prevNav)
        props.toggleNav()
    }


    return (
        <div className='box-border navbar-button flex rounded-full items-center place-content-center p-3 bg-gray-100 hover:bg-gray-200 shadow-xl m-5' onClick={toggleNavbar}>
            {isNavbarOn===false ? <div>
                <FontAwesomeIcon icon={faCircle} size='3x'/>
            </div> : <FontAwesomeIcon icon={faTimes} size='3x'/>
            }
        </div>
    )
}