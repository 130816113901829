export default function AboutMeContent(){
    return (
        <div>
            <div className="p-4 text-xl font-semibold md:text-3xl md:font-bold bg-gray-200 md:p-5 rounded-2xl shadow-md m-2">
                <h1>
                    About me
                </h1>
            </div>
            <div className="p-4 bg-gray-200 md:p-5 rounded-2xl shadow-md m-2">
                <p>
                    I am half-german, half-turkish developer & student in the Netherlands.
                </p>
            </div>
            <div className="p-4 bg-gray-200 md:p-5 rounded-2xl shadow-md m-2">
                <h3 className="text-lg md:font-bold m-1">
                    Languages
                </h3>
                <ol role="list" className="list-disc m-1">
                    <li>
                        English
                    </li>
                    <li>
                        German
                    </li>
                    <li>
                        Turkish
                    </li>
                    <li>
                        Dutch
                    </li>
                </ol>
            </div>
            <div className="p-4 bg-gray-200 md:p-5 rounded-2xl shadow-md m-2">
                <h3 className="text-lg md:font-bold m-1">
                    Hobbies
                </h3>
                <ol role="list" className="list-disc m-1">
                    <li>
                        Guitar
                    </li>
                    <li>
                        Football
                    </li>
                    <li>
                        Tennis
                    </li>
                    <li>
                        Running
                    </li>
                    <li>
                        Reading
                    </li>
                    <li>
                        Table Tennis
                    </li>
                </ol>
            </div>
            <div className="p-4 bg-gray-200 md:p-5 rounded-2xl shadow-md m-2">
                <h3 className="text-lg md:font-bold m-1 ">
                    Technologies & Coding Languages
                </h3>
                <ol role="list" className="list-disc m-1">
                    <li>
                        Python
                    </li>
                    <li>
                        Javascript
                    </li>
                    <li>
                        C++
                    </li>
                    <li>
                        HTML & CSS (not technically a coding language)
                    </li>
                    <li>
                        Tailwind
                    </li>
                    <li>
                        Bootstrap
                    </li>
                    <li>
                        React.js
                    </li>
                    <li>
                        Tensorflow
                    </li>
                    <li>
                        Sklearn
                    </li>
                    <li>
                        Numpy & Pandas
                    </li>
                </ol>
            </div>
        </div>
    )
}