import NavbarButton from "./NavbarButton"
import { useState } from "react"
import Navbar from "./Navbar"
import Blogs from "./blogentries.json"
import BlogEntry from "./BlogEntry"


export default function Blog(){
    const [showNav, setShowNav] = useState(false)
    function toggleNav(){
        setShowNav(prevNav => !prevNav)
    }
    
    var allBlogs = []
    for (let i = 0; i < Blogs.length;i++){
        const curBlog = Blogs[i]
        allBlogs.push(
            <BlogEntry 
                key={curBlog.id} 
                title={curBlog.title}
                description={curBlog.description}
                date={curBlog.date}
                id={curBlog.id}
            />
        )
    }

    if (showNav){
        return (
            <div className="bg-gray-900 min-w-screen min-h-screen place-content-center flex justify-center align-middle content-center hover:bg">
                <NavbarButton toggleNav={toggleNav}/>
                <Navbar/>
                
            </div>
        )
    }
    else{
        return (
            <div className="bg-slate-200 min-w-screen min-h-screen text-gray-900 flex flex-col items-center b-10 experience-full-bg">
                <NavbarButton toggleNav={toggleNav}/>
                <div className="half-width">
                    {allBlogs}
                </div>
            </div>
        )
    }
    
}