import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AboutMe from "./components/AboutMe"
import Experience from './components/Experience';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Projects from "./components/Projects"
import Blog from "./components/Blog"
import BlogItem from "./components/BlogItem"
import FullBlogContent from "./components/blogentries.json"


var BlogRouters = []
for (let i = 0; i < FullBlogContent.length;i++){
  BlogRouters.push(
    <Route 
      path={`/blog/${FullBlogContent[i].id}`} 
      key={i}
      element={
        <BlogItem 
          key={FullBlogContent[i].id} 
          id={FullBlogContent[i].id}
          index={i}
        />
      }
    />
  )
}


ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<App/>} />
      <Route path="/aboutme" element={<AboutMe/>}/>
      <Route path="/experience" element={<Experience/>}/>
      <Route path="/projects" element={<Projects/>}/>
      <Route path="/blog" element={<Blog/>} exact/>
      {BlogRouters}
      
    </Routes>
  </Router>
  ,
  document.getElementById('root')
);
