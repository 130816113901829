export default function ProjectContent(){
    return (
        <div>
            <div className="projects-title p-5 rounded-3xl bg-gray-200 project-item">
                <h1 className="text-xl md:text-3xl lg:text-6xl font-bold md:font-extrabold p-4">
                    Projects
                </h1>
            </div>  
            <div className="project p-5 rounded-3xl bg-gray-200 project-item">
                <h2 className="text-lg md:text-2xl lg:text-4xl md:font-bold p-3">
                    PaxMUN website
                </h2>
                <h3 className="text-md lg:text-2xl pl-3 pr-3 pt-2 pb-2">
                    <a href="https://paxmun.nl/">Find it here</a>
                </h3>
                <p className="p-3">
                    This project is build with vanilla javascript, bootstrap, html & css.<br/>
                    The main thing I learned from this project was responsive design.<br/>
                </p>
            </div>
            <div className="project p-5 rounded-3xl bg-gray-200 project-item">
                <h2 className="text-lg md:text-2xl lg:text-4xl md:font-bold p-3">
                    Dr Jekyll & Mr Hyde Context
                </h2>
                <h3 className="text-md lg:text-2xl pl-3 pr-3 pt-2 pb-2">
                    <a href="https://mxxaxx.github.io/dr-jekyll/">
                    Find it here
                    </a>
                </h3>
                <p className="p-3">
                    This project taught me about Dr Jekyll & Mr Hyde context.<br/>
                    I also learnt about a cool colour pallet. (I didn't bother with responsive design)
                </p>
            </div>
        </div>
    )
}