export default function ExperienceContent(){
    return (
        <div>
            <div className=" p-5 m-5 shadow-2xl rounded-3xl bg-gray-200">
                <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold p-3">
                    Experience & Qualifications
                </h1>
            </div>
            <div className="p-5 m-5 shadow-2xl rounded-3xl bg-gray-200">
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-xl m-5 font-semibold" id="debate">
                    Debate
                </h2>
                <p className="m-5">
                    I have been part of my schools debate club for 4 years, <br/>
                    recently joining the competitive debate team and beginning to prepare for competitions.<br/>
                    Debating is not only enjoyable, but it is an intellectual stimulation, which teaches me<br/>
                    valuable skills, such as critical thinking, public speaking and structured writing. <br/>
                </p>
            </div>
            <div className="p-5 m-5 shadow-2xl rounded-3xl bg-gray-200">
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-xl m-5 font-semibold" id="debate">
                    MUN
                </h2>
                <p className="m-5">
                    So far I have been to 1 MUN conference as a delegate, <br/>
                    I am currently helping organise the conference <a href="http://www.paxmun.nl/" style={{textDecoration:"underline"}}>PAXMUN</a> as the Head of IT. <br/>
                    MUN teaches me how to navigate through conflict prone world correctly, <br/>
                    furthermore, it allows me to explore viewpoints different from my own,<br/> 
                    a key skill in the divided contemporary world.
                </p>
            </div>
            <div className="p-5 m-5 shadow-2xl rounded-3xl bg-gray-200">
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-xl m-5 font-semibold" id="debate">
                    Competitive programming
                </h2>
                <p className="m-5">
                    Currently, I am just beginning my competitive programming journey,<br/>
                    mr profile is <a href="https://codeforces.com/profile/EMMET23" style={{textDecoration:"underline"}}>here.</a><br/>
                    I practice competitive programming almost daily, aiming (but not always achieving) my goal of one problem each day.<br/>
                    Competitive programming club run by a fellow competitive programmer in my school furhter increases <br/>
                    my aptitude in data structures & algorithms.
                </p>
            </div>
            <div className="p-5 m-5 shadow-2xl rounded-3xl bg-gray-200">
                <h2 className="text-lg sm:text-xl md:text-2xl lg:text-xl m-5 font-semibold" id="debate">
                    GCSEs
                </h2>
                <p className="m-5">
                    I have one A* in iGCSE dutch.
                </p>
            </div>
        </div>
    )
}