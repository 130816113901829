import NavbarButton from "./NavbarButton"
import { useState } from "react"
import Navbar from "./Navbar"
import ProjectContent from "./ProjectContent"

export default function Projects(){
    const [showNav, setShowNav] = useState(false)
    function toggleNav(){
        setShowNav(prevNav => !prevNav)
    }
    if (showNav){
        return (
            <div className="bg-gray-900 min-w-screen min-h-screen place-content-center flex justify-center align-middle content-center hover:bg">
                <NavbarButton toggleNav={toggleNav}/>
                <Navbar/>
                
            </div>
        )
    }
    else{
        return (
            <div className="bg-slate-200 min-w-screen min-h-screen text-gray-900 flex flex-col items-center b-10 projects-full-bg">
                <NavbarButton toggleNav={toggleNav}/>
                <div>
                    <ProjectContent/>
                </div>
            
            </div>
        )
    }
    
}