import Blogs from "./blogentries.json"
import { useState } from "react"
import Navbar from "./Navbar"
import NavbarButton from "./NavbarButton"



export default function BlogItem(props){
    const [showNav, setShowNav] = useState(false)
    function toggleNav(){
        setShowNav(prevNav => !prevNav)
    }
    const BLOG  = Blogs[props.index]

    var content = []
    for (let i = 0; i < BLOG.text.length;i++){
        content.push(
            <div key={`Blog${i}`} className="p-5 pr-12 sm:pr-5">
                {BLOG.text[i]}
            </div>
        )
        if (i < BLOG.images.length){
            content.push(
                <div  key={`Image${i}`} className="p-5 pr-12 sm:pr-5">
                    <img src={require("./blog" + BLOG.images[i])} alt="This is an image that was supposed to be in the blog but now isn't" />
                </div>
            )
        }
    }



    if (showNav){
        return (
            <div className="bg-gray-900 min-w-screen min-h-screen place-content-center flex justify-center align-middle content-center hover:bg">
                <NavbarButton toggleNav={toggleNav}/>
                <Navbar/>
                
            </div>
        )
    }
    else{
        return (
            <div className="bg-gray-200 min-w-screen min-h-screen text-gray-900 flex flex-col items-center b-10 overflow-x-hidden">
                <NavbarButton toggleNav={toggleNav}/>
                <div className="half-width shadow-lg p-5 md:p-8 bg-gray-200 rounded-2xl mt-5">
                    <h1 className="p-5 font-extrabold text-lg md:text-2xl lg:text-4xl pr-12 sm:pr-5">
                        {BLOG.title}
                    </h1>
                    <h3 className="text-md md:text-xl lg:text-2xl pr-10 md:pr-5 p-5">
                        {BLOG.description}
                    </h3>
                    <div className="text-sm md:text-md lg:text-lg p-2 shadow-sm rounded-3xl">
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}