import { Link } from "react-router-dom"

export default function BlogEntry(props){
    return (
        <Link to={`/blog/${props.id}`}>
            <div className="project-item p-5 bg-gray-200 rounded-2xl">
                <h1 className="text-lg sm:text-xl md:text-3xl lg:text-5xl p-5">
                    {props.title}
                </h1>
                <h3 className="text-md sm:text-lg md:text-xl lg:text-2xl p-5">
                    {props.description}
                </h3>
                <h4>
                    {props.date}
                </h4>
            </div>
        </Link>
    )
}