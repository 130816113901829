import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
export default function Home(){
    function copyToClip(){
        navigator.clipboard.writeText("malikbreitbach@icloud.com")
    }
    return (
        <div >
            <div className="mt-10 bg-amber-200 shadow-lg p-5 rounded-3xl">
                <blockquote className="lg:text-6xl sm:text-xl text-md font-bold from-orange-50 bg-gradient-to-r to-orange-100 shadow-lg p-5 rounded-full" id="home-quote">
                        "I think, therefore I am"
                </blockquote>
                <br/>
                <label htmlFor="home-quote" className="lg:text-xl">- René Descartes</label>
            </div>
            <div className="min-w-screen shadow-2xl mt-5 p-5 home-content rounded-xl">
                <h1 className="text-lg lg:text-5xl font-bold p-5 shadow-sm m-3">
                    <span className="font-extrabold text-orange-900">I am</span> Malik Breitbach
                </h1>
                <p className="p-5 shadow-sm m-3 font-semibold lg:text-lg sm:text-md text-sm space-y-5">
                    <p><span className="font-extrabold text-orange-900">I am</span> a <span className="effect-boy">student</span> at the British School in the Netherlands.<br/></p>
                    <p><span className="font-extrabold text-orange-900">I am</span> a <span className="effect-boy">competitive coder</span>.<br/></p>
                    <p><span className="font-extrabold text-orange-900">I am</span> a <span className="effect-boy l">programmer.</span><br/></p>
                    <p><span className="font-extrabold text-orange-900">I am</span> a <span className="effect-boy ">mathematician.</span><br/></p>
                    <p>But most importantly, <span className="font-extrabold text-orange-900">I am</span> a <span className="effect-boy">problem solver</span>.</p>
                </p>
            </div>
         
            <div className="flex flex-col mt-5 hover:bg-amber-200 shadow-2xl duration-1000 p-10 rounded-3xl items-center">
                <h3 className="font-bold lg:text-4xl sm:text-xl text-lg ">
                    Contact Me
                </h3>
                <br/>
                <a href="https://github.com/mxxaxx" className="p-3 hover:bg-blue-400 duration-1000 rounded-3xl m-2 cursor-pointer flex justify-center items-center">
                    <FontAwesomeIcon icon={faGithub} size='2x'/><span className="text-md sm:text-lg lg:text-2xl">: mxxaxx</span>
                </a>
                <a onClick={copyToClip} title="click to copy to clipboard" className="p-3 hover:bg-blue-400 duration-1000 rounded-3xl m-2 cursor-pointer flex justify-center items-center">
                    <FontAwesomeIcon icon={faEnvelope} size='2x'/><span className="text-md sm:text-lg lg:text-2xl">: malikbreitbach@icloud.com</span>
                </a>
            </div>
          
        </div>
        
    )
}