import NavbarButton from "./components/NavbarButton"
import { useState } from "react"
import Home from "./components/Home"
import Navbar from "./components/Navbar"
export default function App(){
    const [showNav, setShowNav] = useState(false)
    function toggleNav(){
        setShowNav(prevNav => !prevNav)
    }
    if (showNav){
        return (
            <div className="bg-gray-900 min-w-screen min-h-screen place-content-center flex justify-center align-middle content-center hover:bg">
                <NavbarButton toggleNav={toggleNav}/>
                <Navbar/>
                
            </div>
        )
    }
    else{
        return (
            <div className="bg-slate-200 min-w-screen min-h-screen text-gray-900 flex flex-col items-center b-10 overflow-x-hidden">
                <NavbarButton toggleNav={toggleNav}/>
                <Home />
            </div>
        )
    }
    
}