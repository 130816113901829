import NavbarButton from "./NavbarButton"
import { useState } from "react"
import Navbar from "./Navbar"
import AboutMeContent from "./aboutmepage/AboutMeContent";

export default function AboutMe(){
    const [showNav, setShowNav] = useState(false)
    function toggleNav(){
        setShowNav(prevNav => !prevNav)
    }

    

    if (showNav){
        return (
            <div className="bg-gray-900 min-w-screen min-h-screen place-content-center flex justify-center align-middle content-center hover:bg">
                <NavbarButton toggleNav={toggleNav}/>
                <Navbar/>
                
            </div>
        )
    }
    else{
        return (
            <div className="bg-slate-200 min-w-screen min-h-screen text-gray-900 flex flex-col items-center b-10 h-full bg-about-me">
                <NavbarButton toggleNav={toggleNav}/>
                <div className="p-5 flex flex-col items-center about-me-width">
                    
                    <AboutMeContent/>
                    
                </div>
                
            </div>
        )
    }
}