import { Link } from "react-router-dom"
export default function Navbar(){
    return (
        <ul>
            <Link to="/">
                <li className="text-gray-400 text-md sm:text-2xl lg:text-7xl m-5 font-extrabold hover:bg-zinc-900 p-10 rounded-full justify-center flex shadow-2xl">
                    Home
                </li>
            </Link>
            <Link to="/aboutme">
                <li className="text-gray-400 text-md sm:text-2xl lg:text-7xl m-5 font-extrabold hover:bg-zinc-900 p-10 rounded-full justify-center flex shadow-2xl">
                    About me
                </li>
            </Link>
            <Link to="/experience">
                <li className="text-gray-400 text-md sm:text-2xl lg:text-7xl m-5 font-extrabold hover:bg-zinc-900 p-10 rounded-full justify-center flex shadow-2xl">
                    Experience
                </li>
            </Link>
            <Link to="/projects">
                <li className="text-gray-400 text-md sm:text-2xl lg:text-7xl m-5 font-extrabold hover:bg-zinc-900 p-10 rounded-full justify-center flex shadow-2xl">
                    Projects
                </li>
            </Link>
            <Link to="/blog">
                <li className="text-gray-400 text-md sm:text-2xl lg:text-7xl m-5 font-extrabold hover:bg-zinc-900 p-10 rounded-full justify-center flex shadow-2xl">
                    Blog
                </li>
            </Link>
        </ul>
    )
}